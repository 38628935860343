import Cookies from "universal-cookie";
import logo from "../../assets/svg/logo2.svg";
import { useAppDispatch, useAppSelector } from "../libs/hooks";
import { AuthConstant } from "../constants/authConstant";
import { useEffect } from "react";
import { getUserInfo } from "../libs/reducers/userSlice";
import { Link } from "react-router-dom";

export default function Header() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const _token = params.get("access_token");
  const cookie = new Cookies();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const isLogin = useAppSelector((state) => state.userInfo.isLogin);

  if (_token) {
    const expires = new Date();
    expires.setDate(expires.getDate() + AuthConstant.EXPIRES_TOKEN);
    cookie.remove(AuthConstant.ACCESS_TOKEN);
    cookie.remove(AuthConstant.PUBLIC_KEY);
    cookie.set(AuthConstant.ACCESS_TOKEN, _token, {
      path: "/",
      expires: expires,
    });
    window.location.href = "/";
  }

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const login = () => {
    let domain = window.location.origin;
    var url =
      process.env.REACT_APP_AUTH_URL + "/login?redirect_uri=" + domain || "";
    window.location.href = url;
  };

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove(AuthConstant.ACCESS_TOKEN);
    // navigate("/");
    window.location.reload();
  };
  return (
    <>
      <div className="header nav-left">
        <div className="container-fluid px-xxl-5">
          <div className="header-logo">
            <a href="/">
              <img src={logo} alt="" style={{ height: "50px" }} />
            </a>
          </div>
          <div className="header-menu nav-link-hover-line">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="index.html">
                  Trang chủ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages-corporate/about.html">
                  Về NSTD
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages-corporate/clients.html">
                  Clients
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages-corporate/services.html">
                  Dịch vụ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages-corporate/contact.html">
                  Liên hệ
                </a>
              </li>
            </ul>
          </div>
          <div className="header-extra ms-4">
            {!isLogin ? (
              <div
                className="button button-sm button-gradient-5 button-rounded button-hover-slide"
                onClick={login}
              >
                <span data-text="Đăng nhập">Đăng nhập</span>
              </div>
            ) : (
              <>
                <div className="header-menu nav-link-hover-line">
                  <ul className="nav">
                    <li className="nav-item">
                      <i className="bi bi-person-circle fs-4 me-2"></i>
                      <a className="nav-link" href="javascript:void(0)">
                        {userInfo.email}
                      </a>
                      <ul className="nav-dropdown">
                        <li className="nav-dropdown-item">
                          <Link className="nav-dropdown-link" to="">
                            Thông tin cá nhân
                          </Link>
                        </li>
                        <li className="nav-dropdown-item">
                          <Link
                            className="nav-dropdown-link"
                            to="/sang-che-cua-toi"
                          >
                            Sáng chế của tôi
                          </Link>
                        </li>
                        <li className="nav-dropdown-item">
                          <a className="nav-dropdown-link" onClick={logout}>
                            Đăng xuất
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <button className="mobile-menu-toggle">
            <span></span>
          </button>
        </div>
      </div>
    </>
  );
}
