import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import Marquee from "react-fast-marquee";
import tradeIllus from "../../../assets/svg/trade.svg";

export default function HomePage() {
  return (
      <>
        <div className="px-3 px-md-4 px-xxl-5">
          <div className="bg-image parallax border-radius-2" data-bg-src="assets/img/banner.jpg">
            <div className="bg-gradient-5 bg-opacity-20">
              <div className="section-spacing-xl bg-black-40">
                <div className="container text-center py-lg-4">
                  <div className="row g-4">
                    <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                      <h1 className="fw-bold uppercase display-4">Nền Tảng Chuyển Nhượng</h1>
                      <h1 className="fs-3 fw-normal">Dữ Liệu Thị Trường Khoa Học - Công Nghệ Quốc Gia</h1>
                      <ul className="list-inline-2xl mt-4">
                        <li>
                          <a className="button button-lg button-rounded button-gradient-5 button-hover-slide"
                             href="pages-corporate/about.html">
                            <span data-text="Chuyển nhượng">Chuyển nhượng</span>
                          </a>
                        </li>
                        <li>
                          <a className="link-hover-line font-family-secondary ms-lg-2"
                             href="pages-corporate/contact.html">
                            Tìm kiếm
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <div className="container">
            <Swiper
                modules={[Autoplay]}
                spaceBetween={50} // Khoảng cách giữa các slide
                // slidesPerView={6} // Số slide hiển thị cùng lúc
                breakpoints={{
                  0: {
                    // width: 576,
                    slidesPerView: 2,
                  },
                  576: {
                    // width: 576,
                    slidesPerView: 4,
                  },
                  768: {
                    // width: 768,
                    slidesPerView: 6,
                  },
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}>
              <SwiperSlide>
                <img src={require("../../../assets/images/client-1-dark.png")} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../assets/images/client-2-dark.png")} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../assets/images/client-3-dark.png")} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../assets/images/client-4-dark.png")} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../assets/images/client-5-dark.png")} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../assets/images/client-6-dark.png")} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("../../../assets/images/client-1-dark.png")} alt=""/>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div className="container">
          <hr className="hr-diagonal"/>
        </div>

        <div className="section-spacing">
          <div className="container">
            <div className="row g-4 g-lg-5">
              <div className="col-12 col-lg-5 order-lg-2">
                <div className="img-overlap">
                  <div className="img-1">
                    <img src={require("../../../assets/images/corporate-about-1.jpg")} alt=""
                         data-bottom-top="transform: translateY(10px)" data-top-bottom="transform: translateY(-50px)"/>
                  </div>
                  <div className="img-2">
                    <img src={require("../../../assets/images/corporate-about-2.jpg")} alt=""
                         data-bottom-top="transform: translateY(-10px)" data-top-bottom="transform: translateY(60px)"/>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 order-lg-1">
                <h2 className="display-6 fw-medium mb-3">
                  <span className="text-highlight-gradient-5">Nền Tảng Chuyển Nhượng</span> thuộc <strong><br/>Hệ Thống
                  Quản Lý Dữ Liệu Thị Trường Khoa Học - Công Nghệ Quốc Gia</strong>
                </h2>
                <p> Nền tảng đã giúp hàng ngàn giao dịch thành công nhanh, tuẩn thủ chính sách của nhà nước. Đồng thời
                  cũng đem nhiều sáng chế, giải pháp được phát triển và ứng dụng trong cuộc sống. </p>
                <div className="d-flex align-items-center mt-4 mt-xl-5">
                  <ul className="avatar-group">
                    <li>
                      <img src={require("../../../assets/images/testimonial-4.jpg")} alt="" data-rjs="2"/>
                    </li>
                    <li>
                      <img src={require("../../../assets/images/testimonial-5.jpg")} alt="" data-rjs="2"/>
                    </li>
                    <li>
                      <img src={require("../../../assets/images/testimonial-7.jpg")} alt="" data-rjs="2"/>
                    </li>
                  </ul>
                  <div className="d-inline-block ps-3">
                    <p>
                      Hơn 1,000 giao dịch <br className="d-md-block"/>
                      đã được thực hiện
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-spacing pt-0">
          <Marquee speed={50}>
            <div className="marquee-container">
              <div className="marquee bg-white border-radius p-4 p-lg-5">
                <div className="mb-3">
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                <h4 className="mt-4 mb-1">Christina Gray</h4>
                <span className="sm-heading text-dark-50">Creative Director</span>
              </div>
              <div className="marquee bg-white border-radius p-4 p-lg-5">
                <div className="mb-3">
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                  labore</p>
                <h4 className="mt-4 mb-1">Brian Cabrera</h4>
                <span className="sm-heading text-dark-50">COO - Company</span>
              </div>
              <div className="marquee bg-white border-radius p-4 p-lg-5">
                <div className="mb-3">
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                <h4 className="mt-4 mb-1">William Davis</h4>
                <span className="sm-heading text-dark-50">Manager - Company</span>
              </div>
              <div className="marquee bg-white border-radius p-4 p-lg-5">
                <div className="mb-3">
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                  labore</p>
                <h4 className="mt-4 mb-1">Rita Cowan</h4>
                <span className="sm-heading text-dark-50">CMO - Company</span>
              </div>
              <div className="marquee bg-white border-radius p-4 p-lg-5">
                <div className="mb-3">
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                <h4 className="mt-4 mb-1">Janice Fortner</h4>
                <span className="sm-heading text-dark-50">Project Manager</span>
              </div>
              <div className="marquee bg-white border-radius p-4 p-lg-5">
                <div className="mb-3">
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                  <i className="bi bi-star-fill text-yellow"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                  labore</p>
                <h4 className="mt-4 mb-1">Barbara Myers</h4>
                <span className="sm-heading text-dark-50">CTO - Company</span>
              </div>
            </div>
          </Marquee>
        </div>

        <div className="container">
          <hr className="hr-diagonal"/>
        </div>

        <div className="section-spacing pt-5">
          <div className="container">
            <div className="row g-4 g-xl-5 align-items-center">
              <div className="col-12 col-lg-6 col-xl-5 pe-0">
                <h2 className="display-5 fw-medium">
                  <span className="text-highlight-gradient-5">Thủ tục</span> mua bán - chuyển nhượng
                </h2>
                <div className="process-box process-gradient-5  mt-5">
                  <div className="process-order">
                    <span>1</span>
                  </div>
                  <h4>Thẩm định và Định giá Tài sản</h4>
                  <p>Xác định giá trị khoa học công nghệ thông qua <a href="http://dinhgia.dlkhcn.io.vn">Hệ Thống Định
                    Giá</a>, đảm bảo mức giá hợp lý cho bên mua và bên bán.</p>
                </div>
                <div className="process-box process-gradient-5 active mt-4">
                  <div className="process-order">
                    <span>2</span>
                  </div>
                  <h4>Ký kết Hợp đồng Chuyển nhượng</h4>
                  <p>Soạn thảo và ký hợp đồng chuyển nhượng, bao gồm các điều khoản về quyền lợi, nghĩa vụ, và điều kiện
                    chuyển nhượng.</p>
                </div>
                <div className="process-box process-gradient-5 mt-4">
                  <div className="process-order">
                    <span>3</span>
                  </div>
                  <h4>Hoàn tất Chuyển giao và Đăng ký</h4>
                  <p>Thực hiện chuyển giao tài sản KTCN & đăng ký với cơ quan quản lý để xác nhận quyền sở hữu mới, hoàn
                    tất thủ tục pháp lý.</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-7">
                <img src={tradeIllus} alt="" data-bottom-top="transform: translateY(10px)"
                     data-top-bottom="transform: translateY(-50px)"/>
              </div>
            </div>
          </div>
        </div>


        <div className="section-spacing pt-0 px-3 px-md-4 px-xxl-5">
          <div className="bg-image parallax border-radius-2" data-bg-src="assets/images/bg-corporate-2.jpg">
            <div className="bg-gradient-5 bg-opacity-20">
              <div className="section-spacing-lg bg-black-60 px-4 px-md-5 text-center">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                      <div className="h1 display-4 text-white">Bạn là một Nhà Sáng Chế</div>
                      <p className="fs-5">Bạn muốn sự sáng chế, giải pháp KTCN của mình được bảo vệ <br/> và tiếp cận
                        những cơ hội phát triển hơn?</p>
                      <ul className="list-inline-2xl mt-4">
                        <li>
                          <a className="button button-rounded button-lg button-gradient-5"
                             href="pages-corporate/about.html">
                            Đăng ký ngay
                          </a>
                        </li>
                        <li>
                          <a className="link-hover-line" href="pages-corporate/contact.html">
                            Liên hệ
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-spacing pt-0">
          <div className="container icon-2xl">
            <hr className="hr-diagonal"/>
            <div className="py-4">
              <div className="row align-items-center g-1">
                <div className="col-12 col-lg-2">
                  <h2 className="display-4 font-family-poppins fw-bold stroke-text">01</h2>
                </div>
                <div className="col-12 col-lg-8">
                  <h3 className="display-6 fw-medium">Đăng ký thông tin Kỹ Thuật - Công Nghệ</h3>
                  <ul className="list-inline-dot">
                    <li>Thu thập</li>
                    <li>Lưu trữ</li>
                    <li>Tra cứu</li>
                  </ul>
                </div>
                <div className="col-12 col-lg-2 text-lg-end">
                  <a className="d-inline-block mt-3 mt-lg-0" href="pages-corporate/services.html">
                    <i className="ti-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <hr className="hr-diagonal"/>
            <div className="py-4">
              <div className="row align-items-center g-1">
                <div className="col-12 col-lg-2">
                  <h2 className="display-4 font-family-poppins fw-bold stroke-text">02</h2>
                </div>
                <div className="col-12 col-lg-8">
                  <h3 className="display-6 fw-medium">Định giá  Kỹ Thuật - Công Nghệ</h3>
                  <ul className="list-inline-dot">
                    <li>Thẩm định</li>
                    <li>Phân tích</li>
                    <li>Liên tục hoàn thiện</li>
                  </ul>
                </div>
                <div className="col-12 col-lg-2 text-lg-end">
                  <a className="d-inline-block mt-3 mt-lg-0" href="pages-corporate/services.html">
                    <i className="ti-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <hr className="hr-diagonal"/>
            <div className="py-4">
              <div className="row align-items-center g-1">
                <div className="col-12 col-lg-2">
                  <h2 className="display-4 font-family-poppins fw-bold stroke-text">03</h2>
                </div>
                <div className="col-12 col-lg-8">
                  <h3 className="display-6 fw-medium">Bảo hộ Kỹ Thuật - Công Nghệ</h3>
                  <ul className="list-inline-dot">
                    <li>Giám sát, Phát hiện</li>
                    <li>Ngăn chặn</li>
                    <li>Hỗ trợ xử lý tranh chấp </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-2 text-lg-end">
                  <a className="d-inline-block mt-3 mt-lg-0" href="pages-corporate/services.html">
                    <i className="ti-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <hr className="hr-diagonal"/>
            <div className="py-4">
              <div className="row align-items-center g-1">
                <div className="col-12 col-lg-2">
                  <h2 className="display-4 font-family-poppins fw-bold stroke-text">04</h2>
                </div>
                <div className="col-12 col-lg-8">
                  <h3 className="display-6 fw-medium">Chuyển nhượng Kỹ Thuật - Công Nghệ</h3>
                  <ul className="list-inline-dot">
                    <li>Kết nối</li>
                    <li>Hỗ trợ thủ tục</li>
                    <li>Ký kết</li>
                    <li>Chuyển giao</li>
                  </ul>
                </div>
                <div className="col-12 col-lg-2 text-lg-end">
                  <a className="d-inline-block mt-3 mt-lg-0" href="pages-corporate/services.html">
                    <i className="ti-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <hr className="hr-diagonal"/>
          </div>
        </div>


        <div className="section-spacing">
          <div className="container">
            <div className="row g-4 g-xl-5 align-items-center">
              <div className="col-12 col-lg-6">
                <h2 className="display-5 fw-medium">
                  Tin tức <span className="text-highlight-gradient-5">mới nhất</span>
                </h2>
                <ul className="list-inline mt-3">
                  <li>
                    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-prev"
                       href="#">
                      <i className="bi bi-arrow-left"></i>
                      <i className="bi bi-arrow-left"></i>
                    </a>
                  </li>
                  <li>
                    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-next"
                       href="#">
                      <i className="bi bi-arrow-right"></i>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-lg-6">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                  et dolore magna aliqua</p>
                <div className="mt-3">
                  <a className="button-fancy-gradient-5" href="blog/blog-list.html">
                    Xem thêm
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-4 mt-lg-5">
              <Swiper
                  modules={[Pagination]}
                  spaceBetween={40} // Khoảng cách giữa các slide
                  // slidesPerView={3} // Số slide hiển thị cùng lúc
                  breakpoints={{
                    0: {
                      // width: 576,
                      slidesPerView: 1,
                    },
                    576: {
                      // width: 576,
                      slidesPerView: 2,
                    },
                    768: {
                      // width: 768,
                      slidesPerView: 3,
                    },
                  }}
              >
                <SwiperSlide>
                  <div className="float-card">
                    <div className="img-link-box">
                      <a href="blog/blog-single.html">
                        <img src={require("../../../assets/images/blog-slide-1.jpg")} alt=""/>
                      </a>
                    </div>
                    <div className="float-card-text bg-white border-radius">
                      <ul className="list-inline-dash mb-2 font-small">
                        <li>Mar 4, 2024</li>
                        <li>Work</li>
                      </ul>
                      <h4 className="mb-0">
                        <a className="link-hover-line" href="blog/blog-single.html">
                          Best work environment
                        </a>
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="float-card">
                    <div className="img-link-box">
                      <a href="blog/blog-single.html">
                        <img src={require("../../../assets/images/blog-slide-2.jpg")} alt=""/>
                      </a>
                    </div>
                    <div className="float-card-text bg-white border-radius">
                      <ul className="list-inline-dash mb-2 font-small">
                        <li>Mar 4, 2024</li>
                        <li>Lifestyle</li>
                      </ul>
                      <h4 className="mb-0">
                        <a className="link-hover-line" href="blog/blog-single.html">
                          Keeping things simple
                        </a>
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="float-card">
                    <div className="img-link-box">
                      <a href="blog/blog-single.html">
                        <img src={require("../../../assets/images/blog-slide-3.jpg")} alt=""/>
                      </a>
                    </div>
                    <div className="float-card-text bg-white border-radius">
                      <ul className="list-inline-dash mb-2 font-small">
                        <li>Mar 4, 2024</li>
                        <li>Tips</li>
                      </ul>
                      <h4 className="mb-0">
                        <a className="link-hover-line" href="blog/blog-single.html">
                          Tips for a healthy lifestyle
                        </a>
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="float-card">
                    <div className="img-link-box">
                      <a href="blog/blog-single.html">
                        <img src={require("../../../assets/images/blog-slide-4.jpg")} alt=""/>
                      </a>
                    </div>
                    <div className="float-card-text bg-white border-radius">
                      <ul className="list-inline-dash mb-2 font-small">
                        <li>Mar 4, 2024</li>
                        <li>Lifestyle</li>
                      </ul>
                      <h4 className="mb-0">
                        <a className="link-hover-line" href="blog/blog-single.html">
                          Work-Life Balance
                        </a>
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </>
  );
}
