import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { indexRouter } from "./app/routers/indexRouter";
import { Navigate, useRoutes } from "react-router-dom";

function App() {
  let router = useRoutes([{ path: "/", element: <Navigate to="/trang-chu" replace /> }, indexRouter]);

  return <div>{router}</div>;
}

export default App;
